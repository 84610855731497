<template>
  <base-mobile-page>
    <template v-slot:main>
        <el-main style="margin:0 auto;">
          <el-card shadow="never">
              <div slot="header">
                  <h1>Login</h1>
              </div>
              <div style="display:-webkit-box;">
                  <div style="text-align:left;width:300px;">
                      <div style="font-size:14px;font-weight:bold;">Email</div>
                      <div style="margin-top:20px;"><el-input v-model="loginForm.email" placeholder="" type="email"></el-input></div>
                      <div style="margin-top:20px;;font-size:14px;font-weight:bold;">Password</div>
                      <div style="margin-top:20px;"><el-input placeholder="" v-model="loginForm.password" show-password></el-input></div>
                      <div style="margin-top:20px;"><el-button type="danger" style="width:300px;" @click="submitLogin">Sign In</el-button></div>
                      <div style="margin-top:20px;"><el-link href="/register">Create account</el-link></div>
                  </div>
              </div>
          </el-card>
        </el-main>
    </template>
  </base-mobile-page>
</template>

<script>
import BaseMobilePage from '../../components/base/BaseMobilePage.vue'
import ModelLogin from '../viewmodel/ModelLogin.vue'

export default {
    extends: ModelLogin,
    name: 'login',
    components: {
        BaseMobilePage
    }
}
</script>

<style>

</style>